import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Article: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg width="48px" height="48px" viewBox="0 0 48 48" className={clss}>
            <g fill="none" fillRule="evenodd">
                <path d="M22.24 12H33a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1h-3.912" stroke={color} />
                <path d="M21 18.156V13a1 1 0 0 1 1-1h3.912" stroke={color} />
                <rect stroke={color} x="15" y="18" width="14" height="19" rx="1" />
                <path stroke={color} strokeLinecap="square" d="M17.714 22.5h8.572M17.714 26.5h8.572M17.714 30.5h8.572" />
            </g>
        </svg>
    );
};

export default Article;
